<template>
  <div id="footer">
    <img src="@/assets/img/icon.png" class="icon" @click="$router.push('/')">
    <p class="footer-message sp-only">京都府の残土処分、コンクリート販売のことなら<br>京栄資材にお任せください。</p>
    <a href="tel:075-605-6172" class="sp-only mb-10">
            <img src="@/assets/img/phone.png" class="phone">
        </a>
    <div class="footer-right">
        <a href="tel:075-605-6172">
            <img src="@/assets/img/phone.png" class="phone">
        </a>
        <div class="vertical-line pc-only"></div>
        <router-link to="contact" class="footer-contact sp-only">お問い合わせ</router-link>
        <router-link
            :to="links[0]"
            class="footer-links"
        >残土処分</router-link>
        <router-link to="/service" class="footer-links">事業内容</router-link>
        <router-link
            :to="{
                name: 'Home',
                hash: '#top-sec5'
            }"
            class="footer-links"
        >会社概要</router-link>
        <router-link 
            :to="{
                name: 'Home',
                hash: '#access'
            }"
            class="footer-links"
        >アクセス</router-link>
        <router-link to="contact" class="footer-contact pc-only">お問い合わせ</router-link>
    </div>
    <div class="sp-only bottom-menu">
        <a class="bottom-menu-child" href="tel:075-605-6172">
            <img src="@/assets/img/phone-icon.png">電話で相談
        </a>
        <router-link class="bottom-menu-child" to="/contact">
            お問い合わせ
        </router-link>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Footer',
    data: () => ({
        links: [
            {
                name: 'Service',
                hash: '#01'
            }
        ]
    }),
    mounted() {
        // if (location.pathname == "/service") {
        //     this.links[0] = "#01";
        //     this.links.splice();
        //     this.$set(this.links, 0, "#01");
        //     console.log(this.links)
        // }
        console.log(location.pathname)
    }
}
</script>
