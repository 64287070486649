<template>
  <div id="before-footer-contact">
    <h2>お問い合わせ</h2>
    <router-link to="/contact">もっと見る</router-link>
  </div>
</template>

<script>
export default {
  name: 'Contact',

}
</script>
