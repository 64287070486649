<template>
<div id="contact">
    <PageTop title="お問い合わせ" en="CONTACT" />
    <section id="contact-sec1" class="anime">
        <p>
            京栄資材にご興味を持ってくださりありがとうございます。<br>
            残土処分、資材販売、運搬、生コンクリート販売、産業廃棄物収集その他ご相談ございましたらお気軽にお問い合わせください。
        </p>
        <a href="tel:075-605-6172" class="phone">
            <img src="@/assets/img/phone-icon.png">
            <img src="@/assets/img/phone-num.png">
        </a>
    </section>
    <section  id="contact-sec2" class="anime">
        <h2>お問い合わせフォーム</h2>
        <form @submit.prevent="submit">
            <div class="form-row">
                <div class="form-row-label">
                    お名前
                    <span>※必須</span>
                </div>
                <div class="form-row-input name-input">
                    <input type="text" name="last-name" v-model="last_name" placeholder="姓" required />
                    <input type="text" name="first-name" v-model="first_name" placeholder="名" required />
                </div>
            </div>
            <div class="form-row">
                <div class="form-row-label">
                    会社名
                </div>
                <div class="form-row-input">
                    <input type="text" name="company" v-model="company" placeholder="有限会社京栄資材" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-row-label">
                    メールアドレス
                    <span>※必須</span>
                </div>
                <div class="form-row-input">
                    <input type="email" name="email" v-model="email" placeholder="info@example.co.jp" required />
                </div>
            </div>
            <div class="form-row">
                <div class="form-row-label">
                    ご住所
                    <span>※必須</span>
                </div>
                <div class="form-row-input">
                    <input type="text" name="address" v-model="address" placeholder="京都府京都市1-2-3" required />
                </div>
            </div>
            <div class="form-row">
                <div class="form-row-label">
                    お電話番号
                    <span>※必須</span>
                </div>
                <div class="form-row-input">
                    <input type="tel" name="phone_num" v-model="phone" placeholder="12-3456-7890" required />
                </div>
            </div>
            <div class="form-row">
                <div class="form-row-label">
                    お問い合わせ内容
                </div>
                <div class="form-row-input">
                    <textarea type="text" name="body" v-model="body" placeholder="お問い合わせ内容を入力して下さい。" />
                </div>
            </div>
            <div class="policy">
                プライバシーポリシー<br>
                有限会社京栄資材（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
                第1条（個人情報）
                「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
                第2条（個人情報の収集方法）
                当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレスなどの個人情報をお尋ねすることがあります。
                第3条（個人情報を収集・利用する目的）
                当社が個人情報を収集・利用する目的は，以下のとおりです。
                当社サービスの提供・運営のため
                ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
                メンテナンス，重要なお知らせなど必要に応じたご連絡のため
                利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため
                ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため
                有料サービスにおいて，ユーザーに利用料金を請求するため
                上記の利用目的に付随する目的
                第4条（利用目的の変更）
                当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
                利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
                第5条（個人情報の第三者提供）
                当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
                人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
                公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
                国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき
                利用目的に第三者への提供を含むこと
                第三者に提供されるデータの項目
                第三者への提供の手段または方法
                本人の求めに応じて個人情報の第三者への提供を停止すること
                本人の求めを受け付ける方法
                前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
                当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                合併その他の事由による事業の承継に伴って個人情報が提供される場合
                個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合
                第6条（個人情報の開示）
                当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。
                本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
                当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                その他法令に違反することとなる場合
                前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
                第7条（個人情報の訂正および削除）
                ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
                当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
                当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
                第8条（個人情報の利用停止等）
                当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
                前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
                当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
                前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
                第9条（プライバシーポリシーの変更）
                本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
                当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
                第10条（お問い合わせ窓口）
                本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
                住所：京都府京都市伏見区横大路天王前４２−２
                社名：有限会社京栄資材
                Eメールアドレス：kyoueisizai@gmail.com
                以上
            </div>
            <input type="checkbox" v-model="checked" id="policy-checkbox" required />
            <label for="policy-checkbox">個人情報の取り扱い及び上記事項に同意します</label><br>
            <button type="submit" class="form-submit">送信する</button>
        </form>
    </section>

    <Footer />
</div>
</template>
<script>
import PageTop from "@/components/PageTop.vue";
import Footer from "@/components/Footer.vue";
// import smtpjs from "../smtp";


export default {
    name: 'Contact',
    components: {
        PageTop, Footer
    },
    data: () => ({
        checked: false,
        last_name: null,
        first_name: null,
        company: null,
        email: null,
        address: null,
        phone: null,
        body: null,
    }),
    // mounted(){
    //     let smtpScript = document.createElement('script')
    //     smtpScript.setAttribute('src', 'https://smtpjs.com/v3/smtp.js')
    //     document.head.appendChild(smtpScript)
    // },
    methods: {
        submit(event) {
            console.log(event)
            this.axios.post('https://api.kyoueisizai.com/', {
                test: '送信データだよ',
                last_name: this.last_name,
                first_name: this.first_name,
                company: this.company,
                email: this.email,
                address: this.address,
                phone: this.phone,
                body: this.body
            })
            .then(function(r) {
                console.log(r);
                this.$router.push('/ContactComplete')
            }.bind(this))
            .catch(error => console.log(error))
            // const body = "姓:" + this.last_name + "\r\n \r \n 名:" + this.first_name + "\r\n \r \n 会社名:" + this.company + "\r\n \r \n メールアドレス:" + this.email + "\r\n \r \n 住所:" + this.address + "\r\n \r \n 電話番号:" + this.phone + "\r\n \r \n お問い合わせ内容:" + this.body;

            // const sendDoc = () => {
            //     smtpjs.Email.send({
            //         Host : "smtp.gmail.com",
            //         Username : "kyoueishizai.dev@gmail.com",
            //         Password : "wNFvP8hdqqyyeNkD_",
            //         To : "naokiupa@gmail.com",
            //         From : "kyoueishizai.dev@gmail.com",
            //         Subject : "ホームページからのお問い合わせを受信しました",
            //         Body : body
            //     }).then(
            //         message => alert(message)
            //     );
            //     // smtpjs.Email.send({
            //     //     Host : "smtp-relay.sendinblue.com",
            //     //     Username : "terikyute@na-cat.com",
            //     //     Password : "zPhHd62g1YfEQ7GZ",
            //     //     To : "naokiupa@gmail.com",
            //     //     From : "terikyute@na-cat.com",
            //     //     Subject : "ホームページからのお問い合わせを受信しました",
            //     //     Body : body
            //     // }).then(
            //     //     message => alert(message)
            //     // );
            // }
            // sendDoc();
            

            // this.axios.post('/api', {
            //     test: '三郎',
            // })
            //     .then(
            //         function (response) {
            //             console.log(response.data);
            //             this.$router.push('/ContactComplete')
            //         }
            //     )
            //     .catch((e) => alert(e))
            // this.$router.push('/ContactComplete')
        }
    },
    mounted() {
        // this.axios.get('/')
        //         .then(
        //             function (response) {
        //                 console.log(response.data);
        //                 // this.$router.push('/ContactComplete')
        //             }
        //         )
        //         .catch()
        // this.axios.post('https://api.kyoueisizai.com/', {
        //         test: '送信データだよ',
        //         last_name: this.last_name,
        //         first_name: this.first_name,
        //         company: this.company,
        //         email: this.email,
        //         address: this.address,
        //         phone: this.phone,
        //         body: this.body
        //     })
        //     .then(response => console.log(response))
        //     .catch(error => console.log(error))
    }
}
</script>
