<template>
<div id="404">
    <PageTop title="404" en="NOT FOUND" />
    <section id="404-sec1" class="sub-page">
        <p>
            お探しのページが見つかりませんでした。<br>
            一時的にアクセスが出来ない状況にあるか、移動もしくは削除された可能性があります。<br>
            URLをご確認の上再度お試しいただくか、トップページよりお探しください。
        </p>
        <a href="/">
            TOPに戻る
        </a>
    </section>

    <Footer />
</div>
</template>

<script>
import PageTop from "@/components/PageTop.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: '404',
    components: {
        PageTop, Footer
    },
}
</script>
