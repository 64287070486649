var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"footer"}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/icon.png")},on:{"click":function($event){return _vm.$router.push('/')}}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"footer-right"},[_vm._m(2),_c('div',{staticClass:"vertical-line pc-only"}),_c('router-link',{staticClass:"footer-contact sp-only",attrs:{"to":"contact"}},[_vm._v("お問い合わせ")]),_c('router-link',{staticClass:"footer-links",attrs:{"to":_vm.links[0]}},[_vm._v("残土処分")]),_c('router-link',{staticClass:"footer-links",attrs:{"to":"/service"}},[_vm._v("事業内容")]),_c('router-link',{staticClass:"footer-links",attrs:{"to":{
              name: 'Home',
              hash: '#top-sec5'
          }}},[_vm._v("会社概要")]),_c('router-link',{staticClass:"footer-links",attrs:{"to":{
              name: 'Home',
              hash: '#access'
          }}},[_vm._v("アクセス")]),_c('router-link',{staticClass:"footer-contact pc-only",attrs:{"to":"contact"}},[_vm._v("お問い合わせ")])],1),_c('div',{staticClass:"sp-only bottom-menu"},[_vm._m(3),_c('router-link',{staticClass:"bottom-menu-child",attrs:{"to":"/contact"}},[_vm._v(" お問い合わせ ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"footer-message sp-only"},[_vm._v("京都府の残土処分、コンクリート販売のことなら"),_c('br'),_vm._v("京栄資材にお任せください。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"sp-only mb-10",attrs:{"href":"tel:075-605-6172"}},[_c('img',{staticClass:"phone",attrs:{"src":require("@/assets/img/phone.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"tel:075-605-6172"}},[_c('img',{staticClass:"phone",attrs:{"src":require("@/assets/img/phone.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"bottom-menu-child",attrs:{"href":"tel:075-605-6172"}},[_c('img',{attrs:{"src":require("@/assets/img/phone-icon.png")}}),_vm._v("電話で相談 ")])
}]

export { render, staticRenderFns }