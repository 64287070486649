<template>
  <div id="header" :class="{ 'header-white' : bgWhite()}">
    <div>
        <img src="@/assets/img/icon.png" class="icon" @click="$router.push('/')">
        <p class="header-message over1530">京都府の残土処分、コンクリート販売のことなら<br>京栄資材にお任せください。</p>
        <!--ハンバーガーメニューのボタン-->
        <div class="hamburger_btn under1530" @click='ActiveBtn=!ActiveBtn'>
            <span class="line line_01" :class="{'btn_line01':ActiveBtn}"></span>
            <span class="line line_02" :class="{'btn_line02':ActiveBtn}"></span>
            <span class="line line_03" :class="{'btn_line03':ActiveBtn}"></span>
        </div>
        <!--サイドバー-->
        <transition name="menu">
            <div class="menu under1530" v-show="ActiveBtn">
                <img src="@/assets/img/icon.png" class="menu-icon" @click="$router.push('/')">
                <ul>
                    <li>
                        <a
                            href="service#01"
                            class="footer-links"
                            @click.native='ActiveBtn=!ActiveBtn'
                        >残土処分</a>
                    </li>
                    <li>
                        <router-link
                            to="/service"
                            class="footer-links"
                            @click.native='ActiveBtn=!ActiveBtn'
                        >事業内容</router-link>
                    </li>
                    <li>
                        <router-link
                            :to="{
                                name: 'Home',
                                hash: '#top-sec5'
                            }"
                            class="footer-links"
                            @click.native='ActiveBtn=!ActiveBtn'
                        >会社概要</router-link>
                    </li>
                    <li>
                        <router-link 
                            :to="{
                                name: 'Home',
                                hash: '#access'
                            }"
                            class="footer-links"
                            @click.native='ActiveBtn=!ActiveBtn'
                        >アクセス</router-link>
                    </li>

                    <!-- <li>
                        <router-link
                            to="contact"
                            class="footer-contact"
                            @click.native='ActiveBtn=!ActiveBtn'
                        >お問い合わせ</router-link>
                    </li> -->
                </ul>
                <a href="tel:075-605-6172" class="phone">
                    <img src="@/assets/img/phone-white.png" >
                </a>
                <router-link to="contact" class="footer-contact sp-only" @click.native='ActiveBtn=!ActiveBtn'>お問い合わせ</router-link>
                    <!-- </li>
                </ul> -->
            </div>
        </transition>
    </div>
    <div class="footer-right over1530">
        <a href="tel:075-605-6172" class="phone">
            <img src="@/assets/img/phone-icon.png">
            <img src="@/assets/img/phone-num.png">
        </a>
        <div class="vertical-line"></div>
        <a
            href="service#01"
            class="footer-links"
        >残土処分</a>
        <router-link to="/service" class="footer-links">事業内容</router-link>
        <router-link
            :to="{
                name: 'Home',
                hash: '#top-sec5'
            }"
            class="footer-links"
        >会社概要</router-link>
        <router-link 
            :to="{
                name: 'Home',
                hash: '#access'
            }"
            class="footer-links"
        >アクセス</router-link>
        <router-link to="contact" class="footer-contact" @click.native='ActiveBtn=!ActiveBtn'>お問い合わせ ></router-link>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            underFV: false,
            ActiveBtn: false
        };
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (this.$route.path == "/") {
                if (window.innerWidth > 1023) {
                    this.scrollY = window.scrollY;
                    if (!this.underFV) {
                        this.underFV = window.scrollY > 1020;
                    } else if (window.scrollY < 1019) {
                        this.underFV = !this.underFV;
                    }
                }else {
                    this.scrollY = window.scrollY;
                    if (!this.underFV) {
                        this.underFV = window.scrollY > 765;
                    } else if (window.scrollY < 764) {
                        this.underFV = !this.underFV;
                    }
                }
            }
        },
        bgWhite() {
            if (this.$route.path == "/") {
                return this.underFV;
            }else {
                return true;
            }
        }
    },
    computed: {
        getScroll: function () {
            return window.scrollY
        }
    }

}
</script>
