<template>
<div id="service">
    <PageTop title="事業内容" en="SERVICE" />
    <section
        class="main-sec anime"
        v-for="(item, index) in list"
        :key="index"
        :id="'0' + (index + 1)"
    >
        <div class="left-img">
            <img :src="item.img" class="extend-img">
        </div>
        <div class="max-1200">
            <p class="sub-title title-label" v-if="index == 0">{{ item.title }}</p>
            <p
                class="sub-title title-label"
                v-else
                v-for="(title, index3) in item.sp_title"
                :key="index3"
            >{{ title }}</p>
            <p class="sub-desc">{{ item.body }}</p>
            <p class="point">0{{index + 1}}</p>
        </div>
        <div class="flow-list">
            <h2 class="flow-title">{{ item.flow_title }}</h2>
            <ul class="flow-ul">
                <li
                    v-for="(item2, index2) in item.flow"
                    :key="index2"
                >
                    <img :src="item2.img" class="extend-img">
                    <div class="flow-words">
                        <h3>{{ item2.title }}</h3>
                        <p>{{ item2.body }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </section>

    <Contact class="mt-30"/>
    <Footer />
</div>
</template>

<script>
// @ is an alias to /src
import PageTop from "@/components/PageTop.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: 'Home',
    components: {
        PageTop, Contact, Footer
    },
    data: () => ({
        list: [
            {
                img: require('@/assets/img/service-01.jpg'),
                title: "残土処分",
                body: "京栄資材では、京都府を中心に建設現場で出た残土処分を行っております。\n京都南部地域はもちろんあらゆる地域に提携引受け所がございます。\nまずはお気軽にお問い合わせください。",
                flow_title: "残土処分の流れ",
                flow: [
                    {
                        img: require("@/assets/img/service-icon-1.png"),
                        title: "お問い合わせ",
                        body: "まずはお気軽にお問い合わせください！",
                    },
                    {
                        img: require("@/assets/img/service-icon-2.png"),
                        title: "お見積もり",
                        body: "弊社から要件に沿ったお見積りを致します。",
                    },
                    {
                        img: require("@/assets/img/service-icon-3.png"),
                        title: "搬　入",
                        body: "搬入頂いての処分を基本としております。お引き取りにも対応しております。",
                    },
                    {
                        img: require("@/assets/img/service-icon-4.png"),
                        title: "ご精算",
                        body: "お取引後のご精算をさせていただいております。\n量によっては、事前に一部お支払い頂く場合がございます。",
                    },
                ]
            },
            {
                img: require('@/assets/img/service-02.jpg'),
                title: "建材・資材・骨材の運搬及び販売",
                sp_title: [
                    "建材・資材・骨材の",
                    "運搬及び販売"
                ],
                body: "京栄資材では、建設に必要な建材・資材・骨材を販売しております。\n砕石RC40　砕石C40　砂　リサイクル砂　砂利(25-05)その他当社に常時置いていないものも\n配達可能です。京都南部地域はもちろんあらゆる地域に提携引受け所がございます。",
                flow_title: "建材・資材・骨材販売の流れ",
                flow: [
                    {
                        img: require("@/assets/img/service-icon-1.png"),
                        title: "お問い合わせ",
                        body: "まずはお気軽にお問い合わせください！",
                    },
                    {
                        img: require("@/assets/img/service-icon-2.png"),
                        title: "お見積もり",
                        body: "弊社から要件に沿ったお見積りを致します。",
                    },
                    {
                        img: require("@/assets/img/service-icon-3.png"),
                        title: "搬　入",
                        body: "搬入頂いての処分を基本としております。お引き取りにも対応しております。",
                    },
                    {
                        img: require("@/assets/img/service-icon-4.png"),
                        title: "ご精算",
                        body: "お取引後のご精算をさせていただいております。\n量によっては、事前に一部お支払い頂く場合がございます。",
                    },
                ]
            },
            {
                img: require('@/assets/img/service-03.jpg'),
                title: "生コンクリートの製造・販売",
                sp_title: [
                    "生コンクリートの",
                    "製造・販売"
                ],
                body: "京栄資材では、生コンクリートの製造・販売をおこなっております。\n京都府の各エリアを他社と連携して納入致します。お問合せ下さい！",
                flow_title: "生コンクリート販売の流れ",
                flow: [
                    {
                        img: require("@/assets/img/service-icon-1.png"),
                        title: "お問い合わせ",
                        body: "まずはお気軽にお問い合わせください！",
                    },
                    {
                        img: require("@/assets/img/service-icon-2.png"),
                        title: "お見積もり",
                        body: "弊社から要件に沿ったお見積りを致します。",
                    },
                    {
                        img: require("@/assets/img/service-icon-5.png"),
                        title: "ご契約",
                        body: "搬入前にご契約の締結をさせていただいていおります。",
                    },
                    {
                        img: require("@/assets/img/service-icon-3.png"),
                        title: "現場納入",
                        body: "現場まで納入させていただきます。",
                    },
                    {
                        img: require("@/assets/img/service-icon-4.png"),
                        title: "ご精算",
                        body: "納入後のご精算をさせていただいております。\n量によっては、事前に一部お支払い頂く場合がございます。",
                    },
                ]
            },
        ]
    }),
    methods: {
        hoverPrice(index){
            this.$set(this, "hoveringPriceCard", index);
        },
    },
}
</script>
