import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

// import VueScrollTo from 'vue-scrollto'

require('@/assets/sass/main.scss')

Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'api';
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// Vue.use(VueScrollTo, {
//     duration: 700,
//     easing: [0, 0, 0.1, 1],
//     offset: -100,
// })
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')