<template>
<div id="404">
    <PageTop title="お問い合わせ完了" en="THANKS" />
    <section id="404-sec1" class="sub-page">
        <p>
            お問い合わせいただきありがとうございます。<br>
            内容を確認した後、担当者よりご連絡いたします。
        </p>
        <a href="/">
            TOPに戻る
        </a>
    </section>

    <Footer />
</div>
</template>

<script>
import PageTop from "@/components/PageTop.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: 'ContactComplete',
    components: {
        PageTop, Footer
    },
}
</script>
