import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Service from '../views/Service.vue'
import Contact from '../views/Contact.vue'
import ContactComplete from '../views/ContactComplete.vue'
import NotFound from '../views/404.vue'

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/service',
        name: 'Service',
        component: Service
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/ContactComplete',
        name: 'ContactComplete',
        component: ContactComplete
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: async(to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }

        const findEl = async(hash, x) => {
            return document.querySelector(hash) ||
                new Promise((resolve) => {
                    if (x > 50) {
                        return resolve();
                    }
                    setTimeout(() => {
                        resolve(findEl(hash, ++x || 1));
                    }, 100);
                });
        }

        if (to.hash) {
            const el = await findEl(to.hash);

            return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
        }

        return { x: 0, y: 0 };
    },
})

export default router