<template>
    <div id="page-top">
        <h1 class="page-title">{{ title }}</h1>
        <p class="page-title-en">{{ en }}</p>
    </div>
</template>
<script>

export default {
    name: 'PageTop',
    props: {
        title: String,
        en: String
    }
}
</script>
