<template>
  <div id="home">
    <section id="fv" ref="title" class="">
      <div class="max-1200">
        <p class="fv-p1 pc-only">京都府の残土処分、コンクリート販売なら京栄資材</p>
        <p class="fv-p1 sp-only">京都府の残土処分、</p>
        <p class="fv-p1 sp-only">コンクリート販売なら京栄資材</p>
        <h1>建築現場の<br>今を創る。</h1>
        <p class="fv-p2">建設現場からの残土搬出から材料骨材の納入、<br>生コンクリートの販売、廃棄物処理まで一貫してお応えします。</p>
      </div>
    </section>
    <section id="top-sec1" class="anime">
      <div class="max-1200">
        <h2>残土の<span>引取</span>、<span>受入れ</span><br class="sp-only">承ります！</h2>
        <p>
          京栄資材では、京都府を中心に建設現場で出た残土処分を行っております。<br>
          京都府内の幅広い現場へのお引取りも行っておりますのでまずはお気軽にお問い合わせください。<br><br>
          <span>
            ※搬入、搬出はすべて定量でお願いいたします。<br>
            ※残土の状態によってはお引き受けできない場合もございます<br>
            ※京都エリアをほぼカバーいたしておりますが、場所によってはお引き受けできない場合がございます
          </span>
        </p>
      </div>
    </section>
    <section id="top-sec2" class="anime">
      <div class="right-img">
        <img src="@/assets/img/top-sec2.png" class="extend-img">
      </div>
      <div class="max-1200">
          <p class="sub-title title-label">京栄資材ならではの</p>
          <h2 class="main-title"><span>5</span>つのポイント</h2>
          <p class="point">POINT</p>
      </div>
      <div class="point-list">
        <div class="point-card" v-for="(item, index) in pointList" :key="index">
          <p class="point-num title-label">POINT0{{ index + 1 }}</p>
          <h3 class="point-title">{{ item.title }}</h3>
          <p class="point-body">{{ item.body }}</p>
        </div>
      </div>
    </section>
    <section id="top-sec3" class="anime">
      <div class="max-1200">
        <h2 class="sec-title">残土引受の<span>価格</span></h2>
        <div class="card-wrapper">
          <div
            class="price-card"
            v-for="(item, index) in priceList"
            :key="index"
            @mouseover="hoverPrice(index)"
            :class="checkCard(index)"
          >
            <p class="price-title">{{ item.title }}</p>
            <p class="price">
              {{ item.price.toLocaleString() }}
              <span>円</span>
            </p>
            <p class="price-comment">{{ item.comment }}</p>
            <p class="price-note">{{ item.note }}</p>
            <router-link to="/contact" class="price-contact">お問い合わせ</router-link>
            <a href="tel:075-605-6172" class="price-call">電話からお問い合わせ</a>
          </div>
        </div>
      </div>
    </section>
    <section id="top-sec4">
      <!-- <div class="max-1200"> -->
        <div 
          class="service-card anime"
          v-for="(item, index) in serviceList"
          @mouseover="hoverPrice(index)"
          :key="index"
        >
          <p class="service-title">{{ item.title }}</p>
          <p class="service-body">{{ item.body }}</p><br>
          <!-- <router-link
            :to="{
                name: 'Service',
                hash: item.link
            }"
            v-if="item.link != null"
            class="readMore"
          >もっと見る</router-link> -->
          <a 
            :href="'service' + item.link"
            v-if="item.link != null"
            class="readMore"
          >もっと見る</a>
        </div>
      <!-- </div> -->
    </section>
    <section id="top-sec5" class="anime">
      <div class="max-1200">
        <h2 class="sec-title">会社情報</h2>
        <h3 class="sec5-sub-title">会社概要</h3>
        <div class="company-info-wrapper">
          <ul class="company-info">
            <li v-for="(item, index) in companyInfoList['list1']" :key="index">
              <p v-if="item.title != null">
                <strong>{{ item.title }}</strong>
                <span>{{ item.body }}</span>
              </p>
              <p v-else>{{ item.body }}</p>
            </li>
          </ul>
          <ul class="company-info">
            <li v-for="(item, index) in companyInfoList['list2']" :key="index">
              <p v-if="item.title != null">
                <strong>{{ item.title }}</strong>
                <span>{{ item.body }}</span>
              </p>
              <p v-else>{{ item.body }}</p>
            </li>
          </ul>
        </div>
        <h3 class="sec5-sub-title">沿   革</h3>
        <ul class="company-info ci2" id="access">
          <li v-for="(item, index) in companyInfoList['list3']" :key="index">
            <p>
              <strong v-if="item.title != null">{{ item.title }}</strong><br class="sp-only">
              {{ item.body }}
            </p>
          </li>
        </ul>
        <h3 class="sec5-sub-title">アクセス</h3>
        <iframe id="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13085.08985432902!2d135.7400314!3d34.9247031!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8f5e31be7a470958!2z5pyJ6ZmQ5Lya56S-IOS6rOaghOizh-adkA!5e0!3m2!1sja!2sjp!4v1646206581405!5m2!1sja!2sjp" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </section>
    <Contact />
    <Footer />
    <button @click="downloadFile" id="safe_data_sheet"><img src="@/assets/img/pdf.png" class="pdf-img"></button>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'Home',
  components: {
    Contact, Footer
  },
  data: () => ({
    pointList: [
      {
        title: "積み込み無料対応！",
        body: "埋戻しなどの良質土を無料で積み込みいたします。\n現場での残土引き取りの場合は積み込みをお願いしております。"
      },
      {
        title: "環境に配慮した\nリサイクル土を使用",
        body: "リサイクル土での造成地への納入実績があります。"
      },
      {
        title: "現場への資材配達を行います！",
        body: "現場への引き取りはもちろん、資材配達も承っております。\n京都南部地域はもちろんあらゆる地域に提携引受け所があります。"
      },
      {
        title: "専門業者だからこその品揃え",
        body: "資材や建材、砕石、砂、生コンクリートなどの資材もお買い求めいただけます。"
      },
      {
        title: "廃棄物処理まで\nワンストップで対応",
        body: "産業廃棄物の収集運搬まで一貫して承っております。"
      },
    ],
    priceList: [
      {
        title: "2t車",
        price: 14000,
        comment: "お引き取りも承っております",
        note: "対応地：京都市内\n対応時間：8:00~17:00\n可能引受物：残土\n補償：なし"
      },
      {
        title: "3t車",
        price: 15000,
        comment: "お引き取りも承っております",
        note: "対応地：京都市内\n対応時間：8:00~17:00\n可能引受物：残土\n補償：なし"
      },
      {
        title: "4t車",
        price: 19000,
        comment: "お引き取りも承っております",
        note: "対応地：京都市内\n対応時間：8:00~17:00\n可能引受物：残土\n補償：なし"
      },
    ],
    hoveringPriceCard: 1,
    hoveringMore: 0,
    serviceList: [
      {
        title: "残土処分",
        body: "京栄資材では、京都府を中心に建設現場で出た残土処分を行っております。\n京都府内の幅広い現場へのお引取りも行っておりますのでまずはお気軽に\nお問い合わせください。",
        link: "#01"
      },
      {
        title: "建材・資材・骨材の運搬及び販売",
        body: "京栄資材では、建設に必要な建材・資材・骨材を販売しております。\n砕石RC40　砕石C40　砂　砂利(25-05)\nその他当社に常時置いていないものも配達可能です。",
        link: "#02"
      },
      {
        title: "生コンクリートの製造・販売",
        body: "京栄資材では、生コンクリートの製造・販売をおこなっております。\n京都府の各エリアを他社と連携して納入致します。是非お問合せ下さい！",
        link: "#03"
      },
      {
        title: "産業廃棄物収集運搬業",
        body: "パートナー企業と提携し産業廃棄物の収集・運搬も\nお引き受けいたします。残土処分から資材・生コンクリート販売\n廃棄物処理まで一貫してご依頼いただけます。",
        link: null
      },
    ],
    companyInfoList: {
      list1: [
        {
          title: "会社名",
          body: "有限会社 京栄資材"
        },
        {
          title: "代表",
          body: "山本  重和"
        },
        {
          title: "所在地",
          body: "〒612-8294　京都市伏見区横大路天王前42-2"
        },
        {
          title: "TEL",
          body: "075-605-6172"
        },
        {
          title: "営業時間",
          body: "7:30 ~ 16:30"
        },
        {
          title: "取引銀行",
          body: "京都銀行"
        },
        {
          title: null,
          body: "日本工業規格認証工場：JIS A5308レディースミクストコンクリート"
        },
      ],
      list2: [
        {
          title: "法人番号",
          body: "130002022647"
        },
        {
          title: "設立",
          body: "平成16年12月"
        },
        {
          title: "資本金",
          body: "3,000,000円"
        },
        {
          title: "FAX",
          body: "075-605-6173"
        },
        {
          title: "定休日",
          body: "日曜日・祝日(お電話にてご相談ください)"
        },
        {
          title: "関連会社",
          body: "株式会社京栄商事"
        },
        {
          title: null,
          body: "京都広域生コンクリート協同組合　(賛助会員)"
        },
      ],
      list3: [
        {
          title: "2004年12月",
          body: "(有) 京栄資材設立　リサイクルコンクリートの製造販売"
        },
        {
          title: "2007年7月",
          body: "JIS A5308取得表示認証工場となり製造販売する"
        },
        {
          title: "2009年5月",
          body: "(株) 京栄商事設立　建設資材、残土処分の受入、骨材販売を開始"
        },
        {
          title: "2015年5月",
          body: "残土処分などの中継所を京栄資材で引受開始　提携事業先などと業務を拡大"
        },
        {
          title: "2022年4月",
          body: "京都広域生コンクリート協同組合に賛助加盟"
        },
      ],
    },
  }),
  methods: {
    hoverPrice(index){
      this.$set(this, "hoveringPriceCard", index);
    },
    hoverMore(index){
      this.$set(this, "hoveringMore", index);
    },
    checkCard(index) {
      if (this.hoveringPriceCard == index) {
        return "hovering";
      }
    },
    downloadFile() {
      window.open('https://kyoueisizai.com/assets/%E5%AE%89%E5%85%A8%E3%83%87%E3%83%BC%E3%82%BF%E3%82%B7%E3%83%BC%E3%83%88.0892ecc7.pdf', '_blank');
    }
  },
  mounted() {
    if (window.innerWidth < 1024) {
      let st = [];

      for (let index = 0; index < this.companyInfoList['list1'].length; index++) {
        st.push(this.companyInfoList['list1'][index])
        st.push(this.companyInfoList['list2'][index])
      }

      this.$set(this.companyInfoList, "list1", st);
      this.$set(this.companyInfoList, "list2", []);
    }
  }
}
</script>
