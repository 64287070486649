var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'header-white' : _vm.bgWhite()},attrs:{"id":"header"}},[_c('div',[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/icon.png")},on:{"click":function($event){return _vm.$router.push('/')}}}),_vm._m(0),_c('div',{staticClass:"hamburger_btn under1530",on:{"click":function($event){_vm.ActiveBtn=!_vm.ActiveBtn}}},[_c('span',{staticClass:"line line_01",class:{'btn_line01':_vm.ActiveBtn}}),_c('span',{staticClass:"line line_02",class:{'btn_line02':_vm.ActiveBtn}}),_c('span',{staticClass:"line line_03",class:{'btn_line03':_vm.ActiveBtn}})]),_c('transition',{attrs:{"name":"menu"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ActiveBtn),expression:"ActiveBtn"}],staticClass:"menu under1530"},[_c('img',{staticClass:"menu-icon",attrs:{"src":require("@/assets/img/icon.png")},on:{"click":function($event){return _vm.$router.push('/')}}}),_c('ul',[_c('li',[_c('a',{staticClass:"footer-links",attrs:{"href":"service#01"},nativeOn:{"click":function($event){_vm.ActiveBtn=!_vm.ActiveBtn}}},[_vm._v("残土処分")])]),_c('li',[_c('router-link',{staticClass:"footer-links",attrs:{"to":"/service"},nativeOn:{"click":function($event){_vm.ActiveBtn=!_vm.ActiveBtn}}},[_vm._v("事業内容")])],1),_c('li',[_c('router-link',{staticClass:"footer-links",attrs:{"to":{
                              name: 'Home',
                              hash: '#top-sec5'
                          }},nativeOn:{"click":function($event){_vm.ActiveBtn=!_vm.ActiveBtn}}},[_vm._v("会社概要")])],1),_c('li',[_c('router-link',{staticClass:"footer-links",attrs:{"to":{
                              name: 'Home',
                              hash: '#access'
                          }},nativeOn:{"click":function($event){_vm.ActiveBtn=!_vm.ActiveBtn}}},[_vm._v("アクセス")])],1)]),_c('a',{staticClass:"phone",attrs:{"href":"tel:075-605-6172"}},[_c('img',{attrs:{"src":require("@/assets/img/phone-white.png")}})]),_c('router-link',{staticClass:"footer-contact sp-only",attrs:{"to":"contact"},nativeOn:{"click":function($event){_vm.ActiveBtn=!_vm.ActiveBtn}}},[_vm._v("お問い合わせ")])],1)])],1),_c('div',{staticClass:"footer-right over1530"},[_vm._m(1),_c('div',{staticClass:"vertical-line"}),_c('a',{staticClass:"footer-links",attrs:{"href":"service#01"}},[_vm._v("残土処分")]),_c('router-link',{staticClass:"footer-links",attrs:{"to":"/service"}},[_vm._v("事業内容")]),_c('router-link',{staticClass:"footer-links",attrs:{"to":{
              name: 'Home',
              hash: '#top-sec5'
          }}},[_vm._v("会社概要")]),_c('router-link',{staticClass:"footer-links",attrs:{"to":{
              name: 'Home',
              hash: '#access'
          }}},[_vm._v("アクセス")]),_c('router-link',{staticClass:"footer-contact",attrs:{"to":"contact"},nativeOn:{"click":function($event){_vm.ActiveBtn=!_vm.ActiveBtn}}},[_vm._v("お問い合わせ >")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"header-message over1530"},[_vm._v("京都府の残土処分、コンクリート販売のことなら"),_c('br'),_vm._v("京栄資材にお任せください。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"phone",attrs:{"href":"tel:075-605-6172"}},[_c('img',{attrs:{"src":require("@/assets/img/phone-icon.png")}}),_c('img',{attrs:{"src":require("@/assets/img/phone-num.png")}})])
}]

export { render, staticRenderFns }